import React from 'react'
import Layout from '../components/layout'
import Image from 'gatsby-image'
import { graphql } from'gatsby'
import PortableText from '@sanity/block-content-to-react'
import urlBuilder from "@sanity/image-url";
import { useEffect } from 'react'

export const eventData = graphql `
query MyEventsQuery {
    allSanityEvents(sort: {fields: endDate, order: DESC}) {
      edges {
        node {
          _id
          _createdAt
          titleEng
          _rawSubTitleEng
          titleFr
          _rawSubTitleFr
          dates
          times
          _rawBodyEng
          _rawBodyFr
          image {
            asset {
              fluid {
                aspectRatio
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
const urlFor = source =>
  urlBuilder({ projectId: "4iqgwgc6", dataset: "production" }).image(source);

const serializer = {
    types: {
      image: props => (
        <figure>
          <img
            src={urlFor(props.node.asset)
              //.width(600)
              .url()}
            alt={props.node.alt}
          />
        </figure>
      )
    }
  };

const Event = ({ data }) =>{
  const eventsData = data.allSanityEvents.edges
    
  useEffect(( ) => {      
    var accordions = document.getElementsByClassName("collapsible-events")
    /* >>> removed first element is-open.
    var firstElemContent = accordions[0];
    firstElemContent.classList.add("is-open");
    var startSate = firstElemContent.nextElementSibling
    startSate.style.maxHeight = startSate.scrollHeight + "px";
    */
    
    for (var i = 0; i < accordions.length; i++) {
        accordions[i].addEventListener("click", function (event) {
            accordionClick(event)       
        });
    }
    
    var accordionClick = (event) => {
      //console.log(event)
        var targetClicked = event.target;
        /*if(targetClicked.classList.contains("container")){
          targetClicked = event.target.nextElementSibling; 
         //console.log(targetClicked) 
        }
        */
        targetClicked.classList.toggle("is-open");
    
        var content = targetClicked.nextElementSibling; 
        //console.log(content)   
        if (content != null) {
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                var allContent = document.getElementsByClassName("collapsible-content");
                for (var i = 0; i < allContent.length; i++) {
                    if (allContent[i].style.maxHeight) {
                        allContent[i].style.maxHeight = null;
                    }
                }
                content.style.maxHeight = content.scrollHeight + "px";
            }
        }
            var allEvents = document.getElementsByClassName("collapsible-events");
            for (var k = 0; k < allEvents.length; k++) {
                var elem = allEvents[k];
                if ((elem.classList.contains("is-open"))&&(elem !==targetClicked)) {
                    elem.classList.remove("is-open");
                }
            }
    }
  var imgPortraits = document.getElementsByClassName('eventMainImagePort')
  var imgLandscapes = document.getElementsByClassName('eventMainImageLand')

  for(var g=0; g<imgPortraits.length; g++){
    let portraitImg = imgPortraits[g]
    let landImg = imgLandscapes[g]
    let ratio = eventsData[g].node.image.asset.fluid.aspectRatio
    if(ratio >=1.0){
      portraitImg.style.display = "none";
      landImg.style.display = "block"
    }else {
      portraitImg.style.display = "block";
      landImg.style.display = "none"
    }
  }
  
 });

      return(
        <div className="events-background">
        <div className="events-page">
          <Layout>
          <div className= "headerSpace"></div> 
          
              {
                  eventsData.map(({ node }) => (
                    <div className="container" key={node._id}>
                      <button className="collapsible-events">          
                          <li className="eventsTitles">{node.dates}</li>
                          <li className="eventsTitles">{node.times}</li>
                          <li className="eventsTitles">{node.titleFr}</li>
                      </button>
                        <div  key = {node.titleEng}className="collapsible-content">
                          <div className="eventArticle">
                            <div className= "eventTitle">
                              <h3>{node.titleFr}</h3>
                              <PortableText
                                  blocks = { node._rawSubTitleFr } 
                                />
                              <h3 className= "titleEn">{node.titleEng}</h3>
                             
                              <PortableText className= "titleEn"
                                  blocks = { node._rawSubTitleEng } 
                                />
                              
                            </div>

                            <div className="eventMainImagePort"
>                              <Image fluid = {node.image.asset.fluid} alt={'gestalt'} />
                            </div>
                             
                              <div className="frenchContent">
                                <PortableText
                                  blocks = { node._rawBodyFr } 
                                  serializers = { serializer }
                                />
                              </div>
                              <div className="englishContent">
                                <PortableText
                                  blocks = { node._rawBodyEng } 
                                  serializers = { serializer }
                                />
                              </div>
                            <div className="eventMainImageLand"
>                              <Image fluid = {node.image.asset.fluid} alt={'gestalt'} />
                            </div>
                          </div>
  
                        </div>
                    </div>
                  ))
              }
    
          </Layout>
        </div>
        </div>
      )
}

export default Event